import React, { FC } from 'react';
import { Layout } from '../components/Layout';


const FaqPage: FC = () => {

    return (
        <Layout>
            <div style={{
                    textAlign: 'center',
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '1.5rem 0 1.5rem 0',
                    boxShadow: '0 0 10px rgb(72 72 72 / 16%)',
                    zIndex: '200',
                    backgroundColor: '#ffffff',
                    flexDirection: 'column'
                }}>
                    <div className='main_text'>
                        Bedankt voor uw aanvraag, wij komen zo snel mogelijk bij u terug!
                    </div>
                </div>

        </Layout>
    )
}

export default FaqPage;